import React from "react"
import Image from "gatsby-image"
import { Link } from "gatsby"

const ArticlesCard = props => {
  return (
    <div to="" className="articlesPage-card">
      <div className="articlesPage-card--image ">
        <Image className="scale-image" fluid={props.image} alt="" />
      </div>
      <div className="articlesPage-card--content">
        <h2 className="articleCard-title">{props.title}</h2>
        <p className="articleCard-overview ">{props.desc}...</p>

        <Link to={`/articles/${props.url}`} className="pageLinks">
          Read Post
        </Link>
      </div>
    </div>
  )
}

export default ArticlesCard

import React from "react"
import Layout from "../components/Layout"
import Cards from "../components/articlesPage/ArticlesCard"
import { useStaticQuery, graphql } from "gatsby"
import Word from "../components/RevealingWord"
import Seo from "../components/Seo"
const Articles = () => {
  const data = useStaticQuery(graphql`
    {
      allPrismicArticles(
        sort: { fields: first_publication_date, order: DESC }
      ) {
        nodes {
          uid
          data {
            article_hero_image {
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            article_title {
              text
            }
            articles_body {
              text
            }
          }
        }
      }
    }
  `)

  const articles = data.allPrismicArticles.nodes

  return (
    <Layout>
      <Seo title={`Kubo | Articles`} isArticle={false} />

      <div className="articlePage-title">
        <h2 className="text-green pageHeadings">
          <Word
            word={`We expound our thoughts and consideration on web development, design, technology and more.`}
          />
        </h2>
      </div>
      <section className="articlePage-grid">
        {articles.map((article, index) => {
          const shortDesc = article.data.articles_body.text
            .split(" ")
            .slice(0, 50)
            .join(" ")

          return (
            <Cards
              key={article.uid}
              title={article.data.article_title.text}
              image={
                article.data.article_hero_image.localFile.childImageSharp.fluid
              }
              url={article.uid}
              desc={shortDesc}
            />
          )
        })}
        {/* <Cards></Cards>
          <Cards></Cards> */}
      </section>
    </Layout>
  )
}

export default Articles
